// eslint-disable-next-line import/prefer-default-export
export const deliveryHero = {
  title: "Full-service delivery at a fraction of the cost",
  subtext:
    "SpotOn Delivery gives you access to DoorDash’s reliable network of drivers with a low flat fee that kicks the third party apps to the curb.\t",
  heroImg: "delivery-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/delivery/demo",
  },
  fatTitle: "Delivery",
};

export const deliveryTrusted = [
  {
    title: "51%",
    subtext: "of Americans order food delivery at least once a week",
  },
  {
    title: "60%",
    subtext:
      "of customers prefer to order via a mobile app when there are exclusive deals",
  },
  {
    title: "18%",
    subtext: "Increase in average spend with online orders",
  },
  {
    title: "$7,500+",
    subtext: "Monthly savings compared to third-party delivery apps",
  },
];

export const deliveryWhiteGlove = {
  mainTitle: "Personalized setup & support",
  title: "Our restaurant specialists are on your side",
  content:
    "Our team of experts will work closely with you to create an online ordering menu that lets your guests order delivery and take-out directly from your website. With full POS integration to streamline operations and integration with DoorDash, we provide fast, reliable delivery to your customers for a first-class experience.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/delivery/demo",
  },
};

export const deliveryLargeFeatures = {
  sectionTitle: "No-hassle local delivery",
  featureBlocks: [
    {
      blockTitle: "Own your data. Ditch the commissions",
      blockSubTitle:
        "Offer delivery directly from your restaurant without having to hire and manage your own drivers. You own and keep guest data, so you can retarget your own guests rather than a third-party party company marketing your competitors.",
      blockImg: "delivery-a.png",
      blockList: [
        "Guests order directly from your website",
        "DoorDash handles delivery logistics",
        "Pay only one flat fee, as low as $4 per order",
        "You own your customer data",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/delivery/demo",
      },
    },
    {
      blockTitle: "Give your guests options",
      blockSubTitle:
        'With SpotOn Delivery, Powered by DoorDash, you make delivery safe and easy for your customers, with their choice of regular or contactless delivery options. DoorDash will send the customer order updates, allow them to track their order, and follow any delivery instructions, such as, "Leave my order on the front porch."  \t\t\t\t',
      blockImg: "delivery-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/delivery/demo",
      },
    },
    {
      blockTitle: "Grow revenue with online ordering",
      blockSubTitle:
        "SpotOn Delivery is built into our powerful online ordering system, so you can create a seamless experience for your guests to order and pay online whether they’re dining in, picking up, ordering delivery, or buying a gift card. And every order gets sent directly to your POS to save you time and avoid mistakes from entering orders manually.",
      blockImg: "delivery-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/delivery/demo",
      },
    },
  ],
};

export const deliveryBusinessTypesCar = {
  title: "Built for restaurants like yours",
  subtext:
    "SpotOn Delivery, Powered by DoorDash, is the most reliable and cost-effective way for your restaurant to reach new customers and minimize delivery costs. ",
  slides: [
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
  ],
};

export const deliveryVideo = {
  title: "See how it works",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "dFtcyDaIkpw",
};

export const deliverySolutionsSection = {
  title: "More great tools to run your business",
  subText: `Save time and grow revenue with integrated SpotOn technology that makes sense for your business.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const deliveryTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const deliveryIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept deliverys and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const deliveryCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/delivery/demo",
  },
};

export const deliveryFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const deliverySolutionsSlides = [
  {
    title: "Restaurant",
    img: "Restaurant.png",
    link: "/restaurant-pos",
  },
  {
    title: "Online Ordering",
    link: "/products/online-order-and-delivery",
    img: "Online ordering.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
];
