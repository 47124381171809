import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  deliveryHero,
  deliveryTrusted,
  deliveryWhiteGlove,
  deliveryLargeFeatures,
  deliveryBusinessTypesCar,
  deliveryVideo,
  deliverySolutionsSection,
  deliveryTestimonials,
  deliveryCTA,
  deliverySolutionsSlides,
  // deliveryFaqs,
} from "../../../data/product-pages/delivery-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import { temakiSlides, heroSuccess } from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "capital-white-glove.png";

const delivery = () => {
  return (
    <Layout>
      <SEO
        title="POS System For Delivery & Full Service Restaurants | SpotOn "
        description="Our full service all-in-one delivery POS system is truly all you need to maximize business efficiency. Move in the right direction with a SpotOn demo."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/delivery/"
      />
      <Hero sectionData={deliveryHero} circleBg={false} />
      <TrustedNumbers numbersArray={deliveryTrusted} />
      <WhiteGlove
        sectionData={deliveryWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={deliveryLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={deliveryBusinessTypesCar} />
      <SuccessStories sectionData={heroSuccess} slides={temakiSlides} />
      <VideoSection sectionData={deliveryVideo} />
      <PartnerLogos />
      <ProductLines
        sectionData={deliverySolutionsSection}
        productSlides={deliverySolutionsSlides}
      />
      <TestmonialReviews sectionData={deliveryTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={deliveryCTA} />
      {/* <Faqs sectionData={deliveryFaqs} /> */}
    </Layout>
  );
};

export default delivery;
